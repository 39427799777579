import React from 'react'
import { Link, navigate } from 'gatsby'
import * as storage from '../utils/storage'

class SignupLogin extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    if (typeof window !== `undefined` && storage.getItem('user')) navigate('/events')
  }
  render() {
    return (
      <div className="has-text-centered">
        <Link className="button is-medium is-link login" to="/login">
          Log in
        </Link>
        <br />
        <center>
          <hr style={{ maxWidth: '660px' }} />
        </center>
        <p className="title is-4" style={{ fontSize: '1.4rem', fontWeight: 400 }}>
          Haven't created a profile yet?
        </p>
        <Link className="button is-medium is-link" to="/create-login">
          Create profile
        </Link>
      </div>
    )
  }
}

export default SignupLogin
