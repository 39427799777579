import React from 'react'
import Layout from '../components/layout'
import SignupLogin from '../components/signup-login'
const IndexPage = () => (
  <Layout homePage>
    <h2 className="has-text-centered title is-2" style={{ marginTop: '-20px' }}>
      Encounter Match
    </h2>
    <div
      className="indexpage"
      style={{ maxWidth: 660, margin: '36px auto 10px', textAlign: 'center' }}
    >
      <p style={{ fontSize: 19 }}>Encounter Match is our free matching platform that lets you mutually match with people after attending one of our events.</p>
      <p style={{ fontSize: 19 }}>You’ll be arranging dates quicker than ever as your matches come through instantly, so you can get in touch right away!</p>
    </div>
    <br />
    <SignupLogin />
  </Layout>
)

export default IndexPage
